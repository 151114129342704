import Illus from "@/assets/icons/wrapped/trinkets-2.svg";
import { YearWrappedResponse } from "@/assets/interfaces";
import { getCardAnimation1, getCardAnimation2 } from "@/assets/js/animations/wrapped/top-customer";
import { useListener } from "@/components/hooks/useListener";
import { AppBtn } from "@/components/ui/buttons";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useRef } from "react";

gsap.registerPlugin(useGSAP);

interface Props extends YearWrappedResponse{}
const TopCustomerCard: React.FC<Props> = ({top_customer,other_top_customers}) => {
  const container = useRef();
  const tlRef = useRef<gsap.core.Timeline>();
  const videoRef = useRef<HTMLVideoElement>();

  useListener("toggle-play-gsap", () => {
    if (tlRef.current) {
      if (tlRef.current.paused()) {
        tlRef.current.play();
        videoRef.current.play();
      } else {
        tlRef.current.pause();
        videoRef.current.pause();
      }
    }
  });

  useGSAP(
    () => {
      const tl = gsap.timeline();
      tlRef.current = tl;

      tl.set(".row", { y: "20", opacity: 0 }).set(".text-card", { y: "50", opacity: 0 });
      tl.set(".trinkets", { opacity: 0 });
      tl.set(".part-2", { display: "none" });
      tl.set(".card-illustration", { opacity: 0 });

      tl.set(".part-2", { display: "none" });
      tl.set(".part-2-text-1", { y: "-20", opacity:0 });
      tl.set(".customer-illustration", { scale: 0, rotate:0, opacity:0 });
      tl.set(".part-2-text-2", { y: "20", opacity:0 });
      tl.set(".part-2-text-3", { y: "-20", opacity:0 });
      tl.set(".thankyou-btn", {  opacity:0 });

      getCardAnimation1(tl);
      getCardAnimation2(tl);
    },
    { scope: container }
  );


  const textRows1 = [
    <span key={0}>Some people just kept </span>,
    <span key={1}>coming back. You had </span>,
    <span key={2} className="text-[#FFD500]"> {other_top_customers.length} repeat customers</span>,
    <span key={3}>in 2024.</span>,
  ];

  return (
    <div ref={container} className="relative flex flex-col items-center justify-start w-full h-full bg-[#FD8524]">
     <div className="w-full absolute left-0 top-0 h-full">
     <div className="w-full h-full absolute z-[2]"></div>
        <video
          ref={videoRef}
          autoPlay
          controls={false}
          className="w-full h-full object-cover"
          src="https://res.cloudinary.com/catlog/video/upload/v1738195041/year%20wrapped/videos/top_customers.mp4"
        />
      </div> 
      <div className="text-center absolute left-0 right-0 mx-auto w-full h-[65%] p-10  leading-tight ">
        <div className="w-full h-full text-2xl sm:text-3xl z-30 text-card relative rounded-[80px] ">
          <Illus className="w-[110%] abs-center trinkets z-0" />

          <div className="z-10 part-1 w-full h-full flex flex-col items-center justify-center">
            {textRows1.map((r, i) => (
              <h3 key={i} className={`text-white row row-${i + 1}`}>
                {r}
              </h3>
            ))}
              <h3 className="text-base mt-5 text-white ">
              <span className="carret-text-1"></span></h3>
          </div>

          <div className="z-10 relative part-2 w-full text-white h-full flex flex-col items-center justify-start py-7.5">
            <div className="flex items-center flex-col gap-2.5 relative">
              <h4 className="text-sm font-semibold part-2-text-1 tracking-widest">YOUR TOP CUSTOMER</h4>

              <div className=" w-[40%] overflow-hidden customer-illustration rounded-full" style={{ aspectRatio: "1/1" }}>
                <img src="https://res.cloudinary.com/catlog/image/upload/v1738195107/year%20wrapped/illustrations/user.png" className="w-full h-full" alt="" />
              </div>
              <h3 className="text-white text-3xl leading-none sm:text-4xl part-2-text-2 font-semibold "> {top_customer.name} </h3>
            </div>
            <h3 className="mt-2.5 z-30 text-sm font-semibold part-2-text-3 tracking-normal">
              They bought from your store {top_customer.orders_count} times!
            </h3>
            <AppBtn href={`https://wa.me?${top_customer.phone}`} className="rounded-full block thankyou-btn mt-5 gap-1.25" color="white">
              {/* prettier-ignore */}
              <svg className="h-6.25" viewBox="0 0 60 60" fill="none">
            <path d="M55 21.7328C55 24.7078 54.525 27.4578 53.7 30.0078H6.3C5.475 27.4578 5 24.7078 5 21.7328C5 14.0078 11.225 7.75781 18.9 7.75781C23.425 7.75781 27.475 9.95781 30 13.3328C32.525 9.95781 36.575 7.75781 41.1 7.75781C48.775 7.75781 55 14.0078 55 21.7328Z" fill="#FD8524"/>
            <path opacity="0.4" d="M53.6969 30C49.7469 42.5 37.5719 49.975 31.5469 52.025C30.6969 52.325 29.2969 52.325 28.4469 52.025C22.4219 49.975 10.2469 42.5 6.29688 30H53.6969Z" fill="#FD8524"/>
            </svg>
              <span className="font-display !font-semibold text-[#FD8524]">Say thank you</span>
            </AppBtn>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopCustomerCard;
